<template>
  <div class="box">
    <div class="page member">
      <van-nav-bar fixed :border="false" :placeholder="true">
        <template #right>
          <van-icon
            name="setting-o"
            size="22"
            color="#fff"
            @click="$router.push('/member/setting')"
          />
        </template>
      </van-nav-bar>
      
        <div class="user-poster">
          <van-cell
            v-if="isLogin"
            class="member-info"
            :title="userinfo.name"
            :label="userinfo.email"
            icon="location-o"
            @click="goProfile"
            is-link
            center
          >
            <template #icon>
              <van-image
                width="1rem"
                height="1rem"
                fit="cover"
                :src="
                  userinfo.avatar ||
                  'https://cdn1.iconfinder.com/data/icons/website-internet/48/website_-_male_user-128.png'
                "
              />
            </template>
          </van-cell>
          <van-cell
            v-else
            class="member-info"
            :title="$t('clicktologinroregister')"
            icon="location-o"
            is-link
            to="/LoginCrm"
          >
            <template #icon>
              <van-image
                width="1rem"
                height="1rem"
                fit="cover"
                :src="
                  userinfo.avatar ||
                  'https://cdn1.iconfinder.com/data/icons/website-internet/48/website_-_male_user-128.png'
                "
              />
            </template>
          </van-cell>
          <!-- <van-row class="user-info">
          <van-col offset="2">
            <van-image
              v-if="userinfo.avatar"
              class="user-avatar"
              round
              width="1.5rem"
              height="1.5rem"
              :src="userinfo.avatar"
            />
            <van-image
              v-else
              class="user-avatar"
              round
              width="1.5rem"
              height="1.5rem"
              src="https://cdn1.iconfinder.com/data/icons/website-internet/48/website_-_male_user-128.png"
            />
          </van-col>
          <van-col span="16" class="user-info-text">
            <div class="user-nickname">
              <span>{{ userinfo.name }}</span>
            </div>
            <template v-if="isLogin">
              <div class="user-mobile">{{ userinfo.email }}</div>
            </template>
            <div v-else class="logintip" @click="goLogin">
              {{ $t("clicktologinroregister") }}
            </div>
          </van-col>
        </van-row>
        <van-icon
          size="24"
          class="right-icon"
          name="setting-o"
          @click="goProfile"
        /> -->
        </div>
        <div class="member-content">
          <div class="cblock user-links">
            <van-cell :icon="newMenu.me_sq" :title="$t('Menu.myApplyApplication')" />
            <van-row class="me_sq">
              <van-col span="6">
                <van-tag v-if="counts[0] > 0" round type="danger">
                  {{ counts[0] }}</van-tag
                >
                <van-icon :name="newMenu.new_account" @click="newAccount" />{{
                  $t("Menu.openAccount")
                }}
                <van-action-sheet
                  v-model="Account.show"
                  :actions="Account.actions"
                  @select="accountOnSelect"
                  :cancel-text="$t('Menu.cancel')"
                  @cancel="onCancel"
                />
              </van-col>
              <van-col span="6">
                <van-tag v-if="counts[1] > 0" round type="danger"
                  >{{ counts[1] }}
                </van-tag>
                <van-icon :name="newMenu.enter_out" @click="funds" />{{
                  $t("Menu.depositOrWithdrawal")
                }}
                <van-action-sheet
                  v-model="Funds.show"
                  :actions="Funds.actions"
                  @select="fundsOnSelect"
                  :cancel-text="$t('Menu.cancel')"
                  @cancel="onCancel"
                />
              </van-col>
              <van-col span="6" @click="Copy.show = true" v-show="showSingal">
                <van-icon :name="newMenu.copy_trader" />{{ $t("Menu.copyTrade") }}
              </van-col>
              <van-col span="6" @click="Signal.show = true" v-show="showSingal">
                <van-icon :name="newMenu.singal" />{{ $t("Menu.signal") }}
              </van-col>
            </van-row>
          </div>
          <div class="cblock margin-top">
            <van-cell-group>
              <van-cell
                :icon="newMenu.qb"
                to="/funds/bankinfo"
                :title="$t('Menu.wallet')"
                is-link
              >
                <slot>
                  <!--<van-tag v-if="credit_counts[0] > 0" round type="danger">{{credit_counts[0]}}</van-tag>-->
                  <van-tag v-if="credit_counts[0] > 0" round type="danger">{{
                    credit_counts[0]
                  }}</van-tag>
                </slot>
              </van-cell>
              <van-cell
                :icon="newMenu.account_ls"
                to="/funds/history"
                :title="$t('Menu.fundsHistory')"
                is-link
              />
              <van-cell
                :icon="newMenu.ye"
                :title="$t('Menu.totalBalance')"
                is-link
                to="/home_account/balance"
              />
              <van-cell
                :icon="newMenu.deposit"
                :title="$t('Menu.totalWithdrawal')"
                is-link
                to="/funds/withdrawals"
              />
              <van-cell
                :icon="newMenu.withdrawal"
                :title="$t('Menu.totalDeposit')"
                is-link
                to="/funds/deposit"
              />
              <van-cell
                v-if="openShare"
                :icon="newMenu.change_gl"
                to="/funds/Underling"
                :title="$t('Menu.underling')"
                is-link
              />
            </van-cell-group>
          </div>
          <div class="cblock margin-top">
            <van-cell-group>
              <van-cell
                :icon="newMenu.zj"
                to="/member/address"
                :title="$t('Menu.credentials')"
                is-link
              />
              <van-cell
                :style="{
                  display: userinfo.haveIbLogin == true ? 'flex' : 'none',
                }"
                v-if="openShare"
                :icon="newMenu.tg"
                to="/share"
                :title="$t('Menu.myLink')"
                is-link
              />
              <!--<van-cell icon="down" to="/terminal" :title="$t('Menu.downloadMetatrade')" is-link />-->
              <van-cell
                icon="chart-trending-o"
                to="/LoginMt4"
                :title="$t('Menu.toTrading')"
                v-if="showTrade != 0"
                is-link
              />
              <van-cell
                icon="info-o"
                to="/about/index"
                :title="$t('Menu.aboutUs')"
                is-link
                style="display: none"
              />
            </van-cell-group>
          </div>
        </div>
        
    </div>
    <van-action-sheet
      v-model="Signal.show"
      :actions="Signal.actions"
      @select="SignalOnSelect"
      :cancel-text="$t('Menu.cancel')"
      @cancel="onCancel"
    />
    <van-action-sheet
      v-model="Copy.show"
      :actions="Copy.actions"
      @select="CopyOnSelect"
      :cancel-text="$t('Menu.cancel')"
      @cancel="onCancel"
    />
    <div class="van-safe-area-bottom"></div>
  </div>
</template>
<script>
import baseConfig from "@/utils/baseConfig";
import { Toast, Notify, Dialog } from "vant";
let subscribed = null;
export default {
  data() {
    return {
      showSingal: baseConfig.showSingal,
      langImg: require("@/assets/image/language.svg"),
      exitImg: require("@/assets/image/exit.svg"),
      groupOrder: 0,
      creditOrder: 0,
      allbanlance: 0,
      alldeposit: 0,
      allwithdrawl: 0,
      isLogin: true,
      openShare: true,
      counts: [],
      credit_counts: [],
      showTrade: baseConfig.terminal,
      userinfo: this.$store.state.userinfo,
      Account: {
        show: false,
        actions: [
          { id: 0, name: this.$t("NewAccount.tradingAccount") },
          { id: 1, name: this.$t("NewAccount.agentAccount") },
        ],
      },
      Funds: {
        show: false,
        actions: [
          { id: 1, name: this.$t("Menu.deposit") },
          { id: 2, name: this.$t("Menu.withdrawals") },
          { id: 3, name: this.$t("Menu.transfer") },
        ],
      },
      Copy: {
        show: false,
        actions: [
          { id: 1, name: this.$t("Menu.aplyForCopy") },
          { id: 2, name: this.$t("Menu.myCopies") },
          { id: 3, name: this.$t("Menu.myApplyCopyLog") },
        ],
      },
      Signal: {
        show: false,
        actions: [
          { id: 1, name: this.$t("Menu.createSignal") },
          { id: 2, name: this.$t("Menu.mySignal") },
          { id: 3, name: this.$t("Menu.myApplySignalLog") },
        ],
      },
      isLoading: false,
      newMenu: {
        new_account: require("@/assets/image/crm/new_account.png"),
        enter_out: require("@/assets/svg/enter_out.svg"),
        singal: require("@/assets/image/crm/signal.png"),
        qb: require("@/assets/svg/qb.svg"),
        account_ls: require("@/assets/image/crm/statement.png"),
        ye: require("@/assets/image/new_crm/balance.png"),
        tg: require("@/assets/image/new_crm/extension.png"),
        lang: require("@/assets/svg/language.svg"),
        me_sq: require("@/assets/svg/me_sq.svg"),
        withdrawal: require("@/assets/image/crm/deposit.png"),
        deposit: require("@/assets/image/crm/withdrawal.png"),
        zj: require("@/assets/image/new_crm/certificate_information.png"),
        copy_trader: require("@/assets/image/crm/copytrade.png"),
        change_gl: require("@/assets/svg/me_kh.svg"),
        change_tg: require("@/assets/image/new_crm/extension.png"),
      },
    };
  },
  computed: {},
  mounted() {
    this.$store.commit("toggleTabbarShow", true);
    // this.loadData();
  },
  beforeDestroy() {},
  methods: {
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    formatMoney(money, power = 2) {
      return money.toFixed(power);
    },
    goProfile() {
      this.$router.push("/member/profile");
    },
    goLogin() {
      this.$router.push("/login");
    },
    toMoneyLog(type) {
      this.$router.push("/member/credit?type=" + type);
    },
    quit() {
        if (!this.$store.state.token) {
            Notify({ type: 'danger', message: this.$t("notLoggenIn") });
      }
      this.$dialog
        .confirm({
          message: this.$t("confirmExit"),
          confirmButtonText: this.$t("ok"),
          cancelButtonText: this.$t("cancel"),
        })
        .then(() => {
          this.$store.commit("logout");
          this.$router.push("/");
        })
        .catch(() => {});
    },
    sorry() {
      this.$toast(this.$t("developing"));
    },
    newAccount() {
      this.Account.show = true;
    },
    accountOnSelect(item) {
      this.Account.show = false;
      this.$router.push({
        path: "/member/newaccount",
        query: {
          id: item.id,
        },
      });
    },
    funds() {
      this.Funds.show = true;
    },
    fundsOnSelect(item) {
      this.Funds.show = false;
      let url = "";
      switch (item.id) {
        case 1:
          url = "/funds/deposit";
          break;
        case 2:
          url = "/funds/withdrawals";
          break;
        case 3:
          url = "/funds/transfer";
          break;
        default:
      }
      this.$router.push({
        path: url,
      });
    },
    CopyOnSelect(value) {
      this.Copy.show = false;
      switch (value.id) {
        case 1:
          this.$router.push({
            path: "/signal/applycopy",
          });
          break;
        case 2:
          this.$router.push({
            path: "/signal/mycopy",
          });
          break;
        case 3:
          this.$router.push({
            path: "/applyingcopy",
          });
          break;
      }
    },
    SignalOnSelect(value) {
      this.Signal.show = false;
      switch (value.id) {
        case 1:
          this.$router.push({
            path: "/signal/applysignal",
          });
          break;
        case 2:
          this.$router.push({
            path: "/signal/mysignal",
          });
          break;
        case 3:
          this.$router.push({
            path: "/applyingsignal",
          });
          break;
      }
    },
    onCancel() {},
  },
};
</script>

<style scoped>
.margin-top .van_image {
  vertical-align: middle;
}

.box {
  overflow-y: auto;
  color: #333;
  background-color: #f2f3f5;
}

.page {
  background-color: #f2f3f5;
}

.cblock {
  margin: 0 10px;
}

.user-poster {
  display: flex;
  flex-direction: column-reverse;
  height: 20%;
  background-color: #13b5b1;
}

.user-poster .right-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #fff;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.user-info {
  padding-top: 1rem;
}

.user-avatar {
  align-items: center;
  margin-right: 10px;
}

.user-info-text {
  text-align: left;
  color: #fff;
  font-size: 14px;
  padding-top: 0.5rem;
}

.user-nickname {
  font-size: 16px;
}

.user-group {
  margin-top: 15px;
}

.user-links a {
  color: #444;
}

.user-links {
  text-align: left;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.user-links .van-row {
  padding: 15px 0;
  font-size: 12px;
  text-align: center;
}

.user-links .van-row .van-col {
  position: relative;
}

.user-links .van-row .van-tag {
  position: absolute;
  left: 60%;
  top: -5px;
}

.user-links .van-row .van-icon {
  display: block;
  font-size: 24px;
  color: #af8755;
}

.points-box {
  height: 90px;
  margin: 0 10px;
  text-align: center;
}

.points-box .van-grid {
  border-radius: 5px;
  margin-top: -35px;
  height: 90px;
  overflow: hidden;
}

.points-box .van-grid .balance-value {
  font-size: 12px;
  font-weight: bold;
  margin-top: 4px;
  color: #b00;
}

.points-box .van-grid .balance-text .balance-name {
  color: #666;
}

.points-box .van-grid .van-grid-item__content {
  padding: 4px;
}

.points-box .van-grid .balance-text {
  margin-top: 0;
  font-size: 10px;
}

.margin-top {
  margin-top: 10px;
  border-radius: 6px;
  overflow: hidden;
}

/* .cblock:last-child {
  padding-bottom: 0.5rem;
} */

.member img {
  vertical-align: baseline;
}
.van-nav-bar {
  background-color: #13b5b1;
}
.van-nav-bar .van-icon {
  color: #fff;
}
.van-pull-refresh {
  background: #13b5b1;
}
.van-pull-refresh >>> .van-pull-refresh__head {
  padding: 50px;
  color: #fff;
}
.van-pull-refresh >>> .van-pull-refresh__track {
  height: 100%;
}
.member-info {
  background-color: #13b5b1;
  color: #fff;
}
.member-info .van-icon {
  color: #fff;
}
.member-info >>> .van-cell__label {
  color: #fff;
}
.member-info >>> .van-cell__title {
  font-size: 20px;
}
.van-image {
  border: 2px solid rgba(250, 250, 250, 0.3);
  border-radius: 11px;
  overflow: hidden;
  margin-right: 12px;
}
.member-content {
  background-color: #f2f3f5;
  padding: 10px 0;
}
.me_sq .van-icon {
  margin-bottom: 10px;
}
.van-icon__image {
  display: inline;
}
</style>