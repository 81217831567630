<template>
  <div>
    <!-- <NavBar :title="$t('Menu.fundsHistory')"></NavBar> -->
    <van-nav-bar
      :title="$t('Menu.fundsHistory')"
      left-arrow
      @click-left="$router.go(-1)"
      :placeholder="true"
      fixed
    />
    <div class="box">
      <div class="box-content">
        <van-dropdown-menu active-color="#25a4bb">
          <van-dropdown-item
            v-model="value"
            :options="option"
            @change="fliter"
          />
          <van-dropdown-item :title="$t('sort')" ref="item">
            <van-cell
              center
              :title="$t('sortByTime')"
              @click="Sort('OrderDate')"
            >
              <template #right-icon>
                <i
                  :class="{
                    fa: true,
                    'fa-long-arrow-down': OrderType == 'desc',
                    'fa-long-arrow-up': OrderType == 'asc',
                  }"
                  :style="{
                    display: OrderName == 'OrderDate' ? 'block' : 'none',
                  }"
                  aria-hidden="true"
                ></i>
              </template>
            </van-cell>
            <van-cell
              center
              :title="$t('sortByAmount')"
              @click="Sort('MoneyUSD')"
            >
              <template #right-icon>
                <i
                  :class="{
                    fa: true,
                    'fa-long-arrow-down': OrderType == 'desc',
                    'fa-long-arrow-up': OrderType == 'asc',
                  }"
                  aria-hidden="true"
                  :style="{
                    display: OrderName == 'MoneyUSD' ? 'block' : 'none',
                  }"
                ></i>
              </template>
            </van-cell>
          </van-dropdown-item>
        </van-dropdown-menu>

        <van-collapse v-model="activeNames">
          <van-loading type="spinner" v-show="records.length == 0" />
          <van-collapse-item v-for="(item, index) in records" :key="index">
            <template #title>
              <div>
                <strong>{{ item.Login }}</strong
                >,<span :style="{ color: formatColor(item.DepositType) }">{{
                  formattype(item.DepositType)
                }}</span>
              </div>
              <div>
                <van-button 
                  v-show="((item.ImgUrl.length > 0 || item.IsSymbol >= 1) && item.DepositType == 1) ? false : true"
                  type="primary" 
                  size="mini" 
                  color="#13b5b1"
                  @click="getUploading(item.OrderNumber)"
                  >{{ $t('Funds.uploadCert') }}</van-button>
              </div>
              <div style="display: none">
                1.232332 &nbsp;<i
                  class="fa fa-long-arrow-right"
                  aria-hidden="true"
                ></i>
                &nbsp;1.23233
              </div>
            </template>
            <template #right-icon>
              <div>
                <div style="color: darkgray">
                  {{
                    item.OrderDate.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
                  }}
                </div>
                <div style="float: right">
                  <span :style="{ color: formatColor(item.DepositType) }">{{
                    item.MoneyUSD
                  }}</span>
                </div>
              </div>
            </template>
            <van-row>
              <van-col span="24"
                >{{ $t("ordernumber") }}: {{ item.OrderNumber }}</van-col
              >
              <van-col span="12"
                >{{ $t("Status") }}:{{ formatStatus(item.IsSymbol) }}</van-col
              >
              <van-col span="12"
                ><span
                  v-if="item.ImgUrl != ''"
                  style="float: right"
                  @click="viewCert(item.ImgUrl)"
                  >{{ $t("Funds.viewCert") }}</span
                ></van-col
              >
            </van-row>
          </van-collapse-item>
        </van-collapse>
      </div>
      <div style="margin-bottom: 100px; text-align: center; padding: 25px 0px">
        <span v-if="records.length < total" @click="nextpage()">{{
          $t("app.clickLoadMore")
        }}</span>
        <span v-else>{{ $t("app.noMoreData") }}</span>
      </div>
    </div>
    <van-action-sheet 
      v-model="show" 
      :title="$t('Funds.uploadCert')"
      :cancel-text="$t('cancel')"
      close-on-click-action
      >
      <div style="margin: 25px 30px 25px;">
        <van-uploader v-model="fileList" multiple :max-count="1" />
      </div>
      <van-button 
        type="info"
        block
        :disabled = "disabled"
        @click="uploading(fileList)">{{ $t('submit') }}</van-button>
    </van-action-sheet>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import { ImagePreview } from "vant";
import { getUserLogins, getmoneyrecored, UPLOAD_EDITAVATAR, uploadCert } from "@/api";
import { Loading } from 'vant';
import { Notify, Dialog, Toast } from 'vant';
export default {
  name: "AccountRecords",
  components: { NavBar },
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      records: [],
      total: 0,
      value: 0,
      color: "green",
      option: [{ text: this.$t("allAccount"), value: 0 }],
      activeNames: [],
      OrderName: "OrderDate",
      OrderType: "desc",
      Search: "",
      show: false,
      fileList: [],
      OrderNumbe: "",
      disabled: false,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {},
  created() {},
  methods: {
    loadData() {
      getUserLogins({}).then((res) => {
        if (res.code == 200) {
          res.msg.forEach((element) => {
            this.option.push({ text: element, value: element });
          });
          this.loadPageData();
        }
      });
    },
    loadPageData() {
      getmoneyrecored({
        offset: this.pageIndex,
        pageSize: 12,
        dateTime: "1970-01-01—9999-12-12",
        orderName: this.OrderName,
        order: this.OrderType,
        search: this.Search,
      }).then((res) => {
        if ((res.code = 200)) {
          if (this.pageIndex == 1) {
            this.records = res.msg.rows;
          } else {
            res.msg.rows.forEach((element) => {
              this.records.push(element);
            });
          }
          this.total = res.msg.total;
        } else {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        }
      });
    },
    nextpage() {
      this.pageIndex++;
      this.loadPageData();
    },
    Sort(value) {
      // debugger
      this.$refs.item.toggle();
      switch (value) {
        case "OrderDate":
          this.OrderName = "OrderDate";
          break;
        case "MoneyUSD":
          this.OrderName = "MoneyUSD";
          break;
      }
      if (this.OrderType == "desc") {
        this.OrderType = "asc";
      } else {
        this.OrderType = "desc";
      }
      this.pageIndex = 1;
      this.loadPageData();
    },
    fliter(value) {
      this.Search = value;
      this.pageIndex = 1;
      this.loadPageData();
    },
    formattype(value) {
      switch (value) {
        case 1:
          return "Deposit";
        case 2:
          return "Transfer Out";
        case 3:
          return "Withdrawal";
      }
    },
    formatColor(value) {
      switch (value) {
        case 1:
          return "green";
          break;
        case 2:
          return "pink";
          break;
        case 3:
          return "#13b5b1";
          break;
      }
    },
    formatMoney(type, value) {
      switch (type) {
        case 1:
          return "+" + value;
          break;
        case 2:
          return "-" + value;
          break;
        case 3:
          return "-" + value;
          break;
      }
    },
    formatStatus(value) {
      switch (value) {
        case -1:
        case 0:
          return this.$t("Funds.processing");
          break;
        case 1:
          return this.$t("Funds.completed");
          break;
        case 2:
          return this.$t("Funds.refused");
          break;
      }
    },
    viewCert(url) {
      ImagePreview(["" + url + ""]);
    },
    getUploading(orderNo) {
      this.OrderNumbe = orderNo;
      this.fileList = [];
      this.show = true;
    },
    async uploading(file) {
      // let res = await http.upload(http.imageurl, file[0]);
      let res = await UPLOAD_EDITAVATAR({base64Str: file[0].content});
      console.log(res,);
      if(res.IsSuccess) {
        uploadCert({
          orderNo: this.OrderNumbe,
          url: res.FilePath,
        }).then((res) => {
          if (res.code == 200) {
              const toast = Toast.loading({
                  type: "success",
                  duration: 0,
                  forbidClick: true,
                  message: "success",
              });
              let second = 3;
              const timer = setInterval(() => {
                  second--;
                  if (second) {
                      toast.message = this.$t("success");
                  } else {
                      clearInterval(timer);
                      Toast.clear();
                  }
              }, 1000);
              this.loadPageData();
              this.show = false;
              this.fileList = [];
          } else {
              Toast.fail(this.$t(res.msg));
          }
        });
      }else {
        this.$toast.fail(res.Message);
        this.$toast.clear();
      }      
    },
  },
};
</script>

<style scoped>
.box {
  overflow-y: auto;
  padding: 10px;
  background-color: #f7f8fa;
  min-height: calc(100vh - 50px);
}
.box-content {
  border-radius: 8px;
  overflow: hidden;
}
.fa {
  color: #25a4bb;
}
.dropdown-menu-option-active-color {
  color: #25a4bb;
}
.van-loading {
  width: 10%;
  margin: 30px auto;
}
</style>
